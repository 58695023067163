import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useCustomersList() {
  // Use toast
  const toast = useToast();

  const fetchCustomers = (customers) => {
    store
      .dispatch("app-customer/fetchCustomers", {})
      .then((response) => {
        customers.value = response.data.companies;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching customers list: ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    fetchCustomers,
  };
}
