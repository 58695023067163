<template>
  <section>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'customer-create' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer Client</span>
        </b-button>
      </b-col>
    </b-row>
    <div class="grid-view wishlist-items mt-2">
      <b-card
        class="itemgrid-card"
        v-for="customer in customers"
        :key="customer.ID"
        no-body
      >
        <b-link :to="{ name: 'customer-detail', params: { id: customer.ID } }">
          <b-img
            :alt="`${customer.name}-${customer.ID}`"
            fluid
            class="card-img-top"
            :src="`${publicPath}` + `images/customers/` + `${customer.logo}`"
          />
        </b-link>
        <!-- Product Details -->
        <b-card-body>
          <h4 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'customer-detail', params: { id: customer.ID } }"
            >
              {{ customer.name }}
            </b-link>
          </h4>
          <b-card-text class="item-description">
            {{ customer.description }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </div>
  </section>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import useCustomersList from "./useCustomersList";
import customerStoreModule from "../customerStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BLink,
    BButton,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    const customers = ref([]);

    const CUSTOMER_APP_STORE_MODULE_NAME = "app-customer";

    const { fetchCustomers } = useCustomersList();

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });

    fetchCustomers(customers);

    return {
      customers,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>
